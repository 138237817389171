var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.question)?_c('div',{staticClass:"practice-box"},[(_vm.page === 1)?[_c('TestingTools_M',{attrs:{"showCalculator":_vm.showCalculator,"showReference":_vm.showReference},on:{"pageEnd":_vm.nextPage,"returnToHome":_vm.returnToHome}})]:_vm._e(),(_vm.page === 2)?[(_vm.showQuestion)?[_c('QuestionWrapper_M',{attrs:{"questions":_vm.questions,"sectionOrder":_vm.sectionOrder,"sectionName":_vm.titleCase(_vm.question.question.sat_subject.name),"examineeName":_vm.examineeName,"questionOrder":_vm.questionOrder,"questionCount":_vm.questions.length,"examTimeSeconds":_vm.testTime,"isReviewPage":_vm.isReviewPage,"hasTime":_vm.hasTime,"showAnnotate":_vm.question.question.sat_subject.name !== 'math' &&
            _vm.question.question.sat_subject.name !== 'math calculator',"showCalculator":_vm.question.question.sat_subject.name === 'math calculator' ||
            (_vm.isNew && _vm.question.question.sat_subject.name === 'math'),"showReference":_vm.question.question.sat_subject.name === 'math' ||
            _vm.question.question.sat_subject.name === 'math calculator'},on:{"onBackQuestion":_vm.onBackQuestion,"onNextQuestion":_vm.onNextQuestion,"onOrderQuestion":_vm.onOrderQuestion,"onSubmitSection":_vm.onSubmitSection,"onTestTimeout":_vm.onTestTimeout,"onExitTest":_vm.onExitTest,"onToReviewPage":function($event){return _vm.onToReviewPage(_vm.questions.length, _vm.seconds)}}},[(_vm.isReviewPage)?[_c('Review_M',{attrs:{"questions":_vm.questions,"sectionOrder":_vm.sectionOrder,"sectionName":_vm.titleCase(_vm.question.question.sat_subject.name)},on:{"onOrderQuestionFromReview":_vm.onOrderQuestionFromReview}})]:[(
              _vm.question.question &&
                _vm.question.question.type === 'default' &&
                _vm.question.question.sat_passage_id > 0 &&
                _vm.question.question.sat_passage.content
            )?_c('MultipleChoiceWithPassage_M',{key:_vm.question.order,attrs:{"passageIntro":_vm.question.question.sat_passage.introduction
                ? _vm.question.question.sat_passage.introduction.intro
                : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.sat_options,"answer":_vm.answer,"marked":_vm.marked,"crossOutActive":_vm.crossOutActive,"crossOutArr":_vm.crossOutArr,"showLine":_vm.question.question.sat_subject.name === 'reading'},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer,"onSwitchCrossOutStatus":_vm.onSwitchCrossOutStatus,"onChangeCrossOut":_vm.onChangeCrossOut}}):(
              _vm.question.question &&
                _vm.question.question.type === 'default' &&
                (_vm.question.question.sat_passage === null ||
                  _vm.question.question.sat_passage.content === null)
            )?_c('MultipleChoice_M',{attrs:{"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.sat_options,"answer":_vm.answer,"marked":_vm.marked,"crossOutActive":_vm.crossOutActive,"crossOutArr":_vm.crossOutArr},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer,"onSwitchCrossOutStatus":_vm.onSwitchCrossOutStatus,"onChangeCrossOut":_vm.onChangeCrossOut}}):(_vm.question.question.type === 'math')?_c('Math_M',{attrs:{"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.sat_options,"answer":_vm.answer,"marked":_vm.marked},on:{"onSwitchMarkStatus":_vm.onSwitchMarkStatus,"onChangeAnswer":_vm.onChangeAnswer}}):_vm._e()]],2)]:[_c('BreakScreen',{attrs:{"examineeName":_vm.examineeName,"breakTimeSeconds":_vm.breakTime[`section${_vm.sectionOrder - 1}${_vm.sectionOrder}`].time},on:{"onBreakTimeout":function($event){_vm.showQuestion = true}}})]]:_vm._e(),_c('el-dialog',{attrs:{"title":this.$t('message.notice'),"visible":_vm.showLeaveAlert,"width":"30%","close-on-click-modal":false,"center":""},on:{"update:visible":function($event){_vm.showLeaveAlert=$event}}},[_c('div',[_c('h6',{domProps:{"innerHTML":_vm._s(
          _vm.$t('toefl.leaveAlert', {
            counts: this.leaveCountText,
            times: this.leaveSeconds,
            second: this.second
          })
        )}})]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default","size":"small"},on:{"click":function($event){_vm.showLeaveAlert = false}}},[_vm._v(" "+_vm._s(this.$t("message.continue"))+" ")])],1)])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }