<template>
  <div class="practice-box" v-if="question">
    <template v-if="page === 1">
      <TestingTools_M
        @pageEnd="nextPage"
        @returnToHome="returnToHome"
        :showCalculator="showCalculator"
        :showReference="showReference"
      />
    </template>
    <template v-if="page === 2">
      <template v-if="showQuestion">
        <QuestionWrapper_M
          :questions="questions"
          :sectionOrder="sectionOrder"
          :sectionName="titleCase(question.question.sat_subject.name)"
          :examineeName="examineeName"
          :questionOrder="questionOrder"
          :questionCount="questions.length"
          :examTimeSeconds="testTime"
          :isReviewPage="isReviewPage"
          :hasTime="hasTime"
          @onBackQuestion="onBackQuestion"
          @onNextQuestion="onNextQuestion"
          @onOrderQuestion="onOrderQuestion"
          @onSubmitSection="onSubmitSection"
          @onTestTimeout="onTestTimeout"
          @onExitTest="onExitTest"
          @onToReviewPage="onToReviewPage(questions.length, seconds)"
          :showAnnotate="question.question.sat_subject.name !== 'math' &&
              question.question.sat_subject.name !== 'math calculator'"
          :showCalculator="
            question.question.sat_subject.name === 'math calculator' ||
              (isNew && question.question.sat_subject.name === 'math')
          "
          :showReference="
            question.question.sat_subject.name === 'math' ||
              question.question.sat_subject.name === 'math calculator'
          "
        >

          <template v-if="isReviewPage">
            <Review_M
              :questions="questions"
              :sectionOrder="sectionOrder"
              :sectionName="titleCase(question.question.sat_subject.name)"
              @onOrderQuestionFromReview="onOrderQuestionFromReview"
            ></Review_M>
          </template>
          <template v-else>
            <MultipleChoiceWithPassage_M
              v-if="
                question.question &&
                  question.question.type === 'default' &&
                  question.question.sat_passage_id > 0 &&
                  question.question.sat_passage.content
              "
              :key="question.order"
              :passageIntro="
                question.question.sat_passage.introduction
                  ? question.question.sat_passage.introduction.intro
                  : null
              "
              :passageContent="getPassage"
              :questionOrder="questionOrder"
              :content="question.question.content"
              :options="question.question.sat_options"
              :answer="answer"
              :marked="marked"
              :crossOutActive="crossOutActive"
              :crossOutArr="crossOutArr"
              @onSwitchMarkStatus="onSwitchMarkStatus"
              @onChangeAnswer="onChangeAnswer"
              @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
              @onChangeCrossOut="onChangeCrossOut"
              :showLine="question.question.sat_subject.name === 'reading'"
            ></MultipleChoiceWithPassage_M>
            <MultipleChoice_M
              v-else-if="
                question.question &&
                  question.question.type === 'default' &&
                  (question.question.sat_passage === null ||
                    question.question.sat_passage.content === null)
              "
              :questionOrder="questionOrder"
              :content="question.question.content"
              :options="question.question.sat_options"
              :answer="answer"
              :marked="marked"
              :crossOutActive="crossOutActive"
              :crossOutArr="crossOutArr"
              @onSwitchMarkStatus="onSwitchMarkStatus"
              @onChangeAnswer="onChangeAnswer"
              @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
              @onChangeCrossOut="onChangeCrossOut"
            ></MultipleChoice_M>
            <Math_M
              v-else-if="question.question.type === 'math'"
              :questionOrder="questionOrder"
              :content="question.question.content"
              :options="question.question.sat_options"
              :answer="answer"
              :marked="marked"
              @onSwitchMarkStatus="onSwitchMarkStatus"
              @onChangeAnswer="onChangeAnswer"
            ></Math_M>
          </template>
        </QuestionWrapper_M>
      </template>
      <template v-else>
        <BreakScreen
          :examineeName="examineeName"
          :breakTimeSeconds="
            breakTime[`section${sectionOrder - 1}${sectionOrder}`].time
          "
          @onBreakTimeout="showQuestion = true"
        />
      </template>
    </template>
    <el-dialog
      :title="this.$t('message.notice')"
      :visible.sync="showLeaveAlert"
      width="30%"
      :close-on-click-modal="false"
      center
    >
      <div>
        <h6
          v-html="
            $t('toefl.leaveAlert', {
              counts: this.leaveCountText,
              times: this.leaveSeconds,
              second: this.second
            })
          "
        ></h6>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="showLeaveAlert = false">
          {{ this.$t("message.continue") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import common from "@/mixins/common";
import { user } from "@ivy-way/material";
import SAT from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: "Test - " + this.CompanyName
    };
  },

  components: {},

  mixins: [common],

  props: [],
  data() {
    return {
      isReviewPage: false,
      userExamAnswers: null,
      sectionOrder: 1,
      page: 0,
      questionOrder: 1,
      practice: null,
      questions: null,
      answer: "",
      marked: false,
      crossOutActive: false,
      crossOutArr: [],
      userExamId: 0,
      practices: [],
      showQuestion: true,
      examId: 0,
      showLeaveAlert: false,
      spent: 0,
      leaveSeconds: 0,
      leaveTimes: 0,
      isNew: false
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    ...mapGetters("user", ["token", "getProfile"]),
    breakTime() {
      if (this.isNew) {
        return {
          section12: {
            hasBreak: false,
            time: 600
          },
          section23: {
            hasBreak: true,
            time: 600
          },
          section34: {
            hasBreak: false,
            time: 600
          }
        };
      } else {
        return {
          section12: {
            hasBreak: true,
            time: 600
          },
          section23: {
            hasBreak: true,
            time: 5
          },
          section34: {
            hasBreak: true,
            time: 600
          }
        };
      }
    },
    showCalculator() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    showReference() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 3 || this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    pastUserExamId() {
      return this.$route.query.user_exam_id;
    },
    examineeName() {
      const { first_name, last_name } = this.$store.getters["user/getProfile"];
      return user.displayName(first_name, last_name);
    },
    getPassage() {
      let passage = "";
      let p1 = this.question.question.sat_passage.content;
      let p2 = this.question.question.sat_passage.introduction
        ? this.question.question.sat_passage.introduction.below_content
        : null;
      if (p2) {
        passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
      } else {
        passage = p1;
      }
      // passage = this.getLine(passage);
      return passage;
    },
    question() {
      if (this.questions) {
        let question = this.questions[this.questionOrder - 1];
        if (question.question["answer"]) {
          question.question["answer"];
          question["answer"] = question.question["answer"];
        } else {
          question.question["answer"] = "";
          question["answer"] = undefined;
        }
        if (question["isMarked"]) {
          question["isMarked"];
        } else {
          question["isMarked"] = false;
        }
        if (question["crossOutActive"]) {
          question["crossOutActive"];
        } else {
          question["crossOutActive"] = false;
        }
        if (question["crossOutArr"]) {
          question["crossOutArr"];
        } else {
          question["crossOutArr"] = [];
        }
        if (!question.question.sat_subject) {
          question.question.sat_subject = {
            id: 5,
            name: "english"
          };
        }
        return question;
      } else {
        return null;
      }
    },
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    testId() {
      return this.$route.params.id;
    },
    practiceType() {
      return this.$route.params.practiceType;
    },
    hasTime() {
      return this.practice && this.practice.marks[1].name.en === "drill";
    }
  },
  watch: {
    question() {
      this.answer = this.question.question["answer"];
      this.marked = this.question["isMarked"];
      this.crossOutActive = this.question["crossOutActive"];
      this.crossOutArr = this.question["crossOutArr"];
    },
    sectionOrder() {
      this.practice = this.practices[this.sectionOrder - 1];
      this.questions = this.practice.exam.exam_questions;
    }
  },

  async mounted() {
    // this.addLineNumber();
    setInterval(() => {
      this.spent++;
    }, 1000);
    let vm = this;
    let leaveStart = 0;
    window.onblur = function() {
      vm.showLeaveAlert = false;
      if (vm.$route.name == "SATTest") {
        leaveStart = vm.spent;
        vm.leaveTimes++;
        document.title =
          "Warning: Do not leave the browser in the middle of the test";
      }
    };
    window.onfocus = function() {
      vm.leaveSeconds += vm.spent - leaveStart;
      if (vm.$route.name == "SATTest") {
        document.title = "Test - " + this.CompanyName;
        if (vm.leaveSeconds > 0) {
          vm.showLeaveAlert = true;
        }
      }
    };

    let testId = this.$route.params.id;
    if (testId) {
      if (this.practiceType === "composePractices") {
        const res = await SAT.getComposePracticeTest(testId);
        this.practices = res.compose_practice.practices;
        this.practice = this.practices[this.sectionOrder - 1];
        this.questions = this.practice.exam.exam_questions;
        this.examId = res.compose_practice.exam.id;
        if (res.compose_practice.scorable.is_new === 1) {
          this.isNew = true;
        }
      } else if (this.practiceType === "singlePractice") {
        const res = await SAT.getPracticeTest(testId);
        this.practices = [res.practice];
        this.practice = this.practices[this.sectionOrder - 1];
        this.questions = this.practice.exam.exam_questions;
        this.examId = this.practice.exam.id;
      }
    }
    this.testTime = this.setExamTimeSeconds();
    this.startTest();
  },

  methods: {
    setNextSection() {
      this.questionOrder = 1;
      this.testTime = this.setExamTimeSeconds();
    },
    setExamTimeSeconds() {
      let testTime = 0;
      if (this.isNew) {
        if (
          this.question.question.sat_subject.name === "math calculator" ||
          this.question.question.sat_subject.name === "math"
        ) {
          testTime = 35 * 60;
        } else {
          if (this.questions && this.questions.length > 27) {
            testTime = 39 * 60;
          } else {
            testTime = 32 * 60;
          }
        }
      } else {
        testTime = this.questions.length * 60;
      }
      return testTime;
    },
    getLine(passage) {
      let new_passage = passage.replace(/[\n]/g, "<br>");
      new_passage = new_passage.replace(
        /<br>/g,
        "</p><p style='margin-bottom:0;height:34px;white-space:nowrap;'>"
      );
      new_passage =
        "<p style='margin-bottom:0;height:34px;white-space:nowrap;'>" +
        new_passage +
        "</p>";
      return new_passage;
    },
    async startTest() {
      let res = null;
      let hasExam = false;
      if (this.practiceType === "composePractices") {
        if (this.pastUserExamId) {
          this.userExamId = this.pastUserExamId;
          res = await SAT.getPastComposePractice(this.userExamId);
          this.setUserTest(res);
        } else {
          hasExam = await SAT.hasTakenComposePractice(this.examId);
          if (hasExam.taken_user_exam_id) {
            this.$confirm(
              this.$t("toefl.Unfinished Test Text"),
              this.$t("toefl.Unfinished Test"),
              {
                showClose: false,
                closeOnClickModal: false,
                confirmButtonText: this.$t("toefl.Resume"),
                cancelButtonText: this.$t("toefl.Start Over")
              }
            )
              .then(async () => {
                this.userExamId = hasExam.taken_user_exam_id;
                res = await SAT.getPastComposePractice(
                  hasExam.taken_user_exam_id
                );
                this.userExamAnswers = res.user_exam.user_exam_answers;
                this.setUserTest(res);
                this.page = 2;
              })
              .catch(async () => {
                // res = await SAT.TakeComposePracticeUserExam(this.examId);
                // this.userExamId = res.user_exam.id;
                // this.nextPage();
                this.$router.go(-1);
              });
          } else {
            // res = await SAT.TakeComposePracticeUserExam(this.examId);
            // this.userExamId = res.user_exam.id;
            // this.nextPage();
            this.$router.go(-1);
          }
        }
      } else if (this.practiceType === "singlePractice") {
        if (this.pastUserExamId) {
          this.userExamId = this.pastUserExamId;
          res = await SAT.getPastPractice(this.userExamId);
          this.setUserTest(res);
        } else {
          hasExam = await SAT.hasTakenPractice(this.examId);
          if (hasExam.taken_user_exam_id) {
            this.$confirm(
              this.$t("toefl.Unfinished Test Text"),
              this.$t("toefl.Unfinished Test"),
              {
                showClose: false,
                closeOnClickModal: false,
                confirmButtonText: this.$t("toefl.Resume"),
                cancelButtonText: this.$t("toefl.Start Over")
              }
            )
              .then(async () => {
                this.userExamId = hasExam.taken_user_exam_id;
                res = await SAT.getPastPractice(hasExam.taken_user_exam_id);
                this.setUserTest(res);
              })
              .catch(async () => {
                // res = await SAT.TakePracticeUserExam(this.examId);
                // this.userExamId = res.user_exam.id;
                // this.nextPage();
                this.$router.go(-1);
              });
          } else {
            // res = await SAT.TakePracticeUserExam(this.examId);
            // this.userExamId = res.user_exam.id;
            // this.nextPage();
            this.$router.go(-1);
          }
        }
      }
    },
    setUserTest(res) {
      this.userExamAnswers = res.user_exam.user_exam_answers;
      this.setAnswers();
      this.sectionOrder =
        res.user_exam.other_status && res.user_exam.other_status.pastOrder
          ? res.user_exam.other_status.pastOrder.sectionOrder
          : this.sectionOrder;
      this.questionOrder =
        res.user_exam.other_status && res.user_exam.other_status.pastOrder
          ? res.user_exam.other_status.pastOrder.questionOrder
          : this.questionOrder;
      this.testTime =
        res.user_exam.other_status &&
        res.user_exam.other_status.pastOrder &&
        res.user_exam.other_status.pastOrder.testTime
          ? res.user_exam.other_status.pastOrder.testTime
          : this.testTime;
      if (res.user_exam.other_status && res.user_exam.other_status.pastOrder) {
        this.other_status = res.user_exam.other_status;
        this.page = 2;
      } else {
        this.other_status = res.user_exam.other_status;
        this.page = 1;
      }
    },
    nextPage() {
      this.page++;
    },
    returnToHome() {
      this.$router.replace({
        name: "SATList"
      });
    },
    onExitTest(reason) {
      this.updateSatAnswers(false);
      this.$router.replace({
        name: "SATList"
      });
    },
    onSwitchMarkStatus(status) {
      this.marked = status.marked;
      this.question["isMarked"] = this.marked;
    },
    onSwitchCrossOutStatus(status) {
      this.crossOutActive = status.crossOutActive;
      this.question["crossOutActive"] = this.crossOutActive;
    },
    onChangeCrossOut(status) {
      if (status.type === "crossOut") {
        if (this.crossOutArr.indexOf(status.letter) === -1) {
          this.crossOutArr.push(status.letter);
        }
        if (this.question.answer === status.letter) {
          this.answer = null;
          this.question.question.answer = null;
          this.question.answer = null;
        }
      } else if (status.type === "undo") {
        let index = this.crossOutArr.indexOf(status.letter);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
      }
      this.question["crossOutArr"] = this.crossOutArr;
    },
    async onNextQuestion(seconds) {
      if (this.questionOrder !== this.questions.length) {
        await this.updateSatAnswers(false, seconds.questionSecondsSpent);
        this.questionOrder++;
      }
    },
    async onBackQuestion(seconds) {
      if (this.questionOrder !== 0) {
        await this.updateSatAnswers(false, seconds.questionSecondsSpent);
        this.questionOrder--;
      }
    },
    async updateSatAnswers(isFinished = false, seconds = 10) {
      if (this.practiceType === "composePractices") {
        await SAT.updateComposePracticeAnswers(this.userExamId, {
          exam_question_id: this.question.id,
          answers: [this.answer],
          is_finished: isFinished,
          is_collected: this.marked,
          answer_time: seconds,
          other_status: {
            pastOrder: {
              sectionOrder: this.sectionOrder,
              questionOrder: this.questionOrder
            },
            leaveInfo: {
              leaveTimes: this.leaveTimes,
              leaveSeconds: this.leaveSeconds
            }
          }
        });
      } else if (this.practiceType === "singlePractice") {
        await SAT.updatePracticeAnswers(this.userExamId, {
          exam_question_id: this.question.id,
          answers: [this.answer],
          is_finished: isFinished,
          is_collected: this.marked,
          answer_time: seconds,
          other_status: {
            pastOrder: {
              sectionOrder: this.sectionOrder,
              questionOrder: this.questionOrder
            },
            leaveInfo: {
              leaveTimes: this.leaveTimes,
              leaveSeconds: this.leaveSeconds
            }
          }
        });
      }
    },
    timeOut() {
      if (this.sectionOrder !== this.practices.length) {
        this.sectionOrder++;
        this.setNextSection();
        if (
          this.breakTime[`section${this.sectionOrder - 1}${this.sectionOrder}`]
            .hasBreak
        ) {
          this.showQuestion = false;
        }
      } else {
        this.$router.push({
          name: "SATResult",
          query: { practiceType: this.practiceType },
          params: { id: this.userExamId }
        });
      }
    },
    async onSubmitSection(seconds) {
      if (this.sectionOrder !== this.practices.length) {
        await this.updateSatAnswers(false, seconds);
        this.sectionOrder++;
        this.setNextSection();
        if (
          this.breakTime[`section${this.sectionOrder - 1}${this.sectionOrder}`]
            .hasBreak
        ) {
          this.showQuestion = false;
        }
      } else {
        await this.updateSatAnswers(true, seconds);
        this.$router.push({
          name: "SATResult",
          query: { practiceType: this.practiceType },
          params: { id: this.userExamId }
        });
      }
    },
    onTestTimeout() {
      this.timeOut();
    },
    onChangeAnswer(value) {
      if (this.crossOutArr.indexOf(value) > -1) {
        let index = this.crossOutArr.indexOf(value);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
      }
      this.answer = value;
      this.question.question.answer = value;
      this.question.answer = value;
    },
    onToReviewPage(order, seconds) {
      // this.updateSatAnswers(false, seconds);
      this.updateSatAnswers(false, 0);
      this.questionOrder = order;
      this.isReviewPage = true;
    },
    onOrderQuestion(order, seconds) {
      this.updateSatAnswers(false, seconds);
      this.questionOrder = order;
    },
    onOrderQuestionFromReview(order) {
      this.questionOrder = order;
      this.isReviewPage = false;
    },
    setAnswers() {
      this.practices.forEach(practice => {
        practice.exam.exam_questions.forEach(question => {
          let answer = null;
          let marked = false;
          this.userExamAnswers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question_id) {
              answer = userAnswer.answers[0];
              marked =
                userAnswer.user_exam_answer_analysis.is_collected === 1
                  ? true
                  : false;
            }
          });
          question.question["answer"] = answer;
          question["answer"] = answer;
          question["isMarked"] = marked;
        });
      });
      this.answer = this.question.question["answer"];
    }
  }
};
</script>

<style scoped>
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
}
.test-paper {
  padding-top: 0;
  max-width: 2000px;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
</style>
<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 90%;
  }
}
</style>
